<template>
	<div class="container">
		<el-form :model="ruleForm" ref="ruleForm" label-width="150px">
			<el-card class="box-card">
				<div class="content">
					<el-form-item
						label="商城名称："
						:inline-message="true"
						prop="mallName"
						:rules="[{required:true,message:'名称不能为空',trigger: 'blur'}]"
					>
						<el-input v-model="ruleForm.mallName" style="width: 300px;"></el-input>
					</el-form-item>
					<el-form-item label="联系电话：" :inline-message="true" prop="bannerGoods">
						<el-input v-model="ruleForm.phonePrefix" style="width: 60px;" maxlength="20"></el-input>
						<span style="margin:0 10px;">--</span>
						<el-input v-model="ruleForm.phone" style="width: 150px;" maxlength="20"></el-input>
					</el-form-item>
                    <el-form-item
						label="首页底色："
						:inline-message="true"
						prop="homeBottomColour"
					>
						<el-input v-model="ruleForm.homeBottomColour" style="width: 200px;"></el-input>
                        <el-tooltip
							class="item"
							effect="dark"
							placement="right"
							style="font-size: 20px;color: #e6a23c;margin-left: 5px;"
						>
							<div slot="content">
								普通色值：#A2D0E4
							</div>
							<i class="el-icon-question"></i>
						</el-tooltip>
					</el-form-item>
                    <el-form-item
						label="首页底图："
						:inline-message="true"
						prop="homeBottomImg"
					>
                        <div class="list-wrap" >
                            <div
                                v-show="ruleForm.homeBottomImg"
                                class="image-item"
                                :style="{ backgroundImage: `url(${ruleForm.homeBottomImg+'?x-oss-process=image/resize,m_fixed,h_146,w_146'})` }"
                                @mouseover.prevent="homeBottomColourIsHover = true"
                                @mouseleave.prevent="homeBottomColourIsHover = false"
                            >
                                <div class="mask" v-show="homeBottomColourIsHover">
                                    <i class="el-icon-delete bin" @click="ruleForm.homeBottomImg = ''"></i>
                                </div>
                            </div>
                            <el-upload
                                v-show="!ruleForm.homeBottomImg"
                                class="upload-machine"
                                :action="imgApi"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess2"
                                :before-upload="beforeAvatarUpload"
                            >
                                <i
                                    class="el-icon-plus"
                                    style="width:144px;height:84px;font-size: 24px;color:#999999;margin-top:60px;"
                                ></i>
                            </el-upload>
                        </div>
					</el-form-item>

					<el-form-item
						label="首页LOGO："
						:inline-message="true"
						prop="logo"
						:rules="[{required:true,message:'LOGO不能为空',trigger: 'blur'}]"
					>
						<el-upload
							class="avatar-uploader"
							:action="imgApi"
							:show-file-list="false"
							:on-success="handleAvatarSuccess"
							:before-upload="beforeAvatarUpload"
						>
							<img
								v-if="ruleForm.logo"
								:src="ruleForm.logo+'?x-oss-process=image/resize,m_fixed,h_146,w_146'"
								style="width:146px;height:146px;"
								class="avatar"
							/>
							<i
								v-else
								class="el-icon-plus"
								style="width:144px;height:84px;font-size: 24px;color:#999999;margin-top:60px;"
							></i>
						</el-upload>
					</el-form-item>

                    <el-form-item
						label="首页LOGO链接:"
						prop="linkUrl"
					>
						<div class="link-modal-info" @click="showModal()">
                            <div class="link-info" v-if="!ruleForm.linkUrl.data">
                                <div class="cont" style="background: transparent;color: #409eff;">请选择页面</div>
                                <div class="icon" style="background: transparent;">
                                    <i class="el-icon-arrow-down" style="color: #409eff;font-size: 16px;background: transparent;"></i>
                                </div>
                            </div>
                            <div class="link-info" v-else>
                                <div class="cont">{{ruleForm.linkUrl.text}}</div>
                                <div class="icon flex center">
                                    <i class="el-icon-arrow-down" style="color: #fff;font-size: 16px;"></i>
                                    <div style="margin-left: 10px;font-size:14px" @click.stop="ruleForm.linkUrl = {}">
                                        x
                                    </div>
                                </div>
                                
                            </div>
                        </div>
					</el-form-item>

					<el-form-item label="商城LOGO：" :inline-message="true" prop="logo">
						<el-upload
							class="avatar-uploader"
							:action="imgApi"
							:show-file-list="false"
							:on-success="handleAvatarSuccess1"
							:before-upload="beforeAvatarUpload"
						>
							<img
								v-if="ruleForm.bottomLogo"
								:src="ruleForm.bottomLogo+'?x-oss-process=image/resize,m_fixed,h_146,w_146'"
								style="width:146px;height:146px"
								class="avatar"
							/>
							<i
								v-else
								class="el-icon-plus"
								style="width:144px;height:84px;font-size: 24px;color:#999999;margin-top:60px;"
							></i>
						</el-upload>
					</el-form-item>
                    <el-form-item label="经营资质图片：" prop="businessQualificationPics">
						<!-- <div class="filter-item" style="width:100px;text-align: right;vertical-align: top;"><span class="tablered">*</span>商品图片</div> -->
						<div class="filter-item" style="vertical-align: top;">
							<upload-img
								@transPicture="transPictureFun"
								:AttachedImgUrls="ruleForm.businessQualificationPics"
								:Type="1"
							></upload-img>
						</div>
					</el-form-item>
				</div>
			</el-card>
		</el-form>
		<div style="height:80px;"></div>
		<div class="box-card footer">
			<el-button style="width:240px;" @click="cancelSupiler()">取消</el-button>
			<el-button style="width:240px;" type="primary" @click="saveSupiler()" :loading="loading">保存</el-button>
		</div>
        <!-- 选择链接弹窗 -->
        <link-modal 
            :showLiveTab="true" 
            :visible="shopVisible" 
            @change="dataChange" 
            @cancel="shopVisible=false">
        </link-modal>
	</div>
</template>

<script>
import { getMallSetting, saveMallSetting } from "@/api/setting";
import uploadImg from '@/components/uploadImgs/uploadPicture';
import config from '@/config/index'
import linkModal from '@/views/components/linkModal';
export default {
    components: {
        uploadImg,
        linkModal
	},
	data () {
		return {
			ruleForm: {
				mallName: '',    //商城名称
				phone: '',       //手机号
				phonePrefix: '', //前缀
				logo: '',
                bottomLogo: '',  //底部logo
                businessQualificationPics:[],  //经营资质图片
                homeBottomImg:'',  //商城首页图片
                homeBottomColour:'',    //商城底色
                linkUrl:{},    //选择链接
			},
			loading: false,  //保存接口防止多次点击
			imgUrl: config.IMG_BASE,
            imgApi: config.UPLOAD_IMG,
            homeBottomColourIsHover:false,  //商城首页图片是否显示删除图标
            shopVisible:false,  //链接弹窗开关
		};
	},
	computed: {

	},
	created () {
		this.getMallSetting();
	},

	methods: {
		//获取详细数据
		async getMallSetting () {
			let res = await getMallSetting();
			this.ruleForm = {
				mallName: res.data.mallName,    //商城名称
				phone: res.data.phone,       //手机号
				phonePrefix: res.data.phonePrefix, //前缀
				logo: res.data.logo,
                bottomLogo: res.data.bottomLogo,  //底部logo
                homeBottomImg: res.data.homeBottomImg,  //首页底部颜色
                homeBottomColour: res.data.homeBottomColour,  //首页底部颜色
                businessQualificationPics:[],  //经营资质图片
                linkUrl:res.data.linkUrl ? JSON.parse(res.data.linkUrl) : {},    //选择链接
            }
            if (res.data.businessQualificationPics) {
                this.ruleForm.businessQualificationPics = JSON.parse(res.data.businessQualificationPics);
            }
		},
		// 上传logo成功钩子
		handleAvatarSuccess (res) {
			this.ruleForm.logo = res.data[0];
			return true;
		},
		// 上传分享图成功钩子
		handleAvatarSuccess1 (res) {
			this.ruleForm.bottomLogo = res.data[0];
			return true;
        },
        // 上传规格图成功钩子
		handleAvatarSuccess2 (res) {
			this.ruleForm.homeBottomImg = res.data[0];
			return true;
		},
		// 上传图前钩子
		beforeAvatarUpload (file) {
			const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif'
			const isLt3M = file.size / 1024 / 1024 < 3;
			if (!isJPG) {
				this.$message.error('上传头像图片必须是 JPG 或者PNG格式!');
			}
			if (!isLt3M) {
				this.$message.error('图片大小请控制在3M以内!');
			}
			return isJPG && isLt3M;
		},
		// 保存
		saveSupiler () {
			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					this.sureSave();
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		//保存
		async sureSave () {
            this.loading = true;
            let temp = JSON.parse(JSON.stringify(this.ruleForm));
            temp.linkUrl = JSON.stringify(temp.linkUrl);
            temp.businessQualificationPics = JSON.stringify(temp.businessQualificationPics);
            console.log(temp);
			let res = await saveMallSetting(temp);

			if (res.success) {
				this.$message({
					type: 'success',
					message: '保存成功!'
				});
			}
			this.loading = false;
		},
		//取消保存
		cancelSupiler () {
			this.getMallSetting();
        },

        // 批量上传的图片
		transPictureFun (datas) {
            this.ruleForm.businessQualificationPics = datas;
        },
        //链接弹窗
        showModal(){
            this.shopVisible = true;
        },
        //链接变更
        dataChange(record){
            this.ruleForm.linkUrl = record;
        },
	},
};
</script>

<style lang="less" scoped>
.container {
	// padding: 10px;
	width: 100%;
	overflow: hidden;

	/deep/.el-textarea {
		width: 500px;
	}
	/deep/.el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		height: 146px;
	}

	/deep/.el-upload:hover {
		border-color: #409eff;
	}
}

.footer {
	position: fixed;
	width: 100%;
	background: #fff;
	bottom: 0;
	text-align: center;
	padding: 20px 0;
	z-index: 100;
	box-shadow: 13px 1px 6px #999;
}
.mallSettingMask{
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.5);
    position: relative;
}
.list-wrap{
    float: left;
    .image-item{
        width: 148px;
        height: 148px;
        position: relative;
        margin-right: 10px;
        margin-bottom: 10px;
        border: 1px solid #c0ccda;
        background-size: 100% 100%;
        border-radius: 6px;
        float: left;
        overflow: hidden;
        cursor: pointer;
    }
    .label{
        width: 46px;
        height: 26px;
        background-color: #13ce66;
        color: #FFFFFF;
        transform: rotate(45deg);
        text-align: center;
        position: absolute;
        right: -17px;
        top: -7px;
        .icon-success{
            transform: rotate(-45deg);
        }
    }
    .mask{
        width: 100%;
        height: 100%;
        border-radius: 6px;
        background-color: rgba(0, 0, 0, 0.5);
        position: relative;
        .bin{
            color: #FFFFFF;
            font-size: 20px;
            position: absolute;
            left: 45%;
            top: 43%;
        }
    }
}
</style>

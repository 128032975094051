<template>
	<image-drag
		:limit="limit"
		ref="imageListUpload"
		:list="file_list"
		:multiple="true"
		:action="uploadUrl"
		:on-error="uploadError"
		:on-success="bannerPicSuccess"
		:before-upload="beforeAvatarUpload"
		@update="updateFile"
	></image-drag>
</template>

<script>
import config from '@/config/index'
import ImageDrag from './uploadImginbatch';
import {
	Loading
} from "element-ui";
export default {
	props: {
		AttachedImgUrls: {
			type: Array
		},
		Type: {
			type: Number
		},
	},
	components: {
		ImageDrag
	},
	data () {
		return {
			imgApi: config.UPLOAD_IMG,
			sortOringinlist: [],
			limit: 15,
			banner_list: [], //ele用的
			file_list: [], //自己用的
			bargain: {
				share_image: ""
			},
			number: "",
			imgUrl: config.IMG_BASE,
			imgApi: config.UPLOAD_IMG,
			// Type:null,
		};
	},
	created () {
		this.limit = this.Type == 2 ? 9 : (this.Type==1?10:1)
	},
	methods: {
		goBack () {
			this.$router.go(-1);
		},
		// 上传图片路径
		uploadUrl () {
			return this.imgApi;
		},
		// 图片长传-之前
		beforeAvatarUpload (file) {
			let self = this;
			let type_arr = ["image/jpeg", "image/png"];
			let type = file.type;
			if (!type_arr.includes(type)) {
				this.$message.error("图片格式不正确,只支持jpg和png类型图片");
				return false;
			}
			const isLt2M = file.size / 1024 / 1024 < 2;
			if (!isLt2M) {
				this.$message.error('已自动过滤大于2M的图片!');
				return false;
			}
			const is_size = new Promise((resolve, reject) => {
				let width = 800;
				let height = 800;
				let img = new Image();
				img.src = window.URL.createObjectURL(file);
				img.onload = () => {
					let valid = img.width === width && img.height === height;
					Loading.service({
						fullscreen: true,
						text: "图片上传中，请稍后"
					});
					resolve(file);
					// if (valid) {
					// 	Loading.service({
					// 		fullscreen: true,
					// 		text: "图片上传中，请稍后"
					// 	});
					// 	resolve(file);
					// } else {
					// 	self.$message.error("请上传800*800px大小的图片!");
					// 	reject();
					// }
				};
			});
			return is_size;
		},
		sortLikeWin (name1, name2) {
			const regexPunc = /[\s!！#$%&(（)）,，、.。;；？@[\]^_`{}~‘’“”《》￥【】+=·…]/
			const regexNum = /[0-9]/
			const regexEng = /[A-Za-z]/
			const regexCh = /[\u4E00-\u9FFF]/
			// 排序大小： 特殊字符 > 数字 > 字母 > 汉字
			// 如果第一个字符相等，再比较下一个字符
			let compareValue = false
			const minLength = Math.min(name1.length, name2.length)
			let i = 0
			do {
				const aIndex = name1.charAt(i)
				const bIndex = name2.charAt(i)
				const nameFirstType = [aIndex, bIndex].map((item) => {
					if (item.match(regexPunc)) {
						return 0
					}
					if (item.match(regexNum)) {
						return 1
					}
					if (item.match(regexEng)) {
						return 2
					}
					if (item.match(regexCh)) {
						return 3
					}
					return -1
				})
				// 如果第一个字符不相等
				if (aIndex !== bIndex) {
					if (nameFirstType[0] !== nameFirstType[1]) {
						compareValue = nameFirstType[0] - nameFirstType[1]
						break
					} else {
						// 中文需根据拼音顺序
						compareValue = aIndex.localeCompare(bIndex, 'zh')
						break
					}
				}
				if (i === minLength) {
					compareValue = name1.localeCompare(name2, 'zh')
					break
				}
				i += 1
			} while (i <= minLength)
			return compareValue
		},
		bannerPicSuccess (res, file, fileList) {
			Loading.service({
				fullscreen: true
			}).close();
			// this.$refs.imageListUpload.clearUpload()
			this.file_list.push(res.data[0])

		},
		// Banner图-成功
		// bannerPicSuccess(res, file, fileList) {
		// 	this.uploadPicture(fileList,0)
		// },
		uploadPicture (fileList, index) {
			if (index > fileList.length) {
				this.$refs.imageListUpload.clearUpload()
				return;
			}
			if (index == fileList.length) {
				Loading.service({
					fullscreen: true
				}).close();
			}

			let getB = new Promise((resolve, reject) => {
				if (fileList[index] && fileList[index].response && fileList[index].response[0]) {
					this.bargain.share_image = fileList[index].response[0]
					this.file_list.push(fileList[index].response[0]);
					this.file_list = [...new Set(this.file_list)]
				}
				setTimeout(() => {
					resolve()
				}, 1000)

			}).catch(e => e)

			Promise.all([getB]).then(data => {
				this.uploadPicture(fileList, ++index)
			}).catch(e => console.log(e));
		},

		// Banner图片上传报错
		uploadError () {
			Loading.service({
				fullscreen: true
			}).close();
		},
		updateFile (val) {
			this.file_list = val;
		}
	},
	watch: {
		file_list (val) {
			this.$emit('transPicture', val);
		},
		AttachedImgUrls (val) {
			this.banner_list = val; //ele用的
			this.file_list = val
		}
	},

};
</script>

<style>
</style>
